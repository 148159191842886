import {
    REQUEST_ADMIN_CONSTANTS,
    RECEIVE_ADMIN_CONSTANTS,
    UPDATE_ADMIN_CONSTANTS,
    ERROR_ADMIN_CONSTANTS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem, reloadConstants} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'

function request() {
    return {
        type: REQUEST_ADMIN_CONSTANTS
    };
}

export function receive(data) {
    return {
        type: RECEIVE_ADMIN_CONSTANTS,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_ADMIN_CONSTANTS,
        errors: data
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_ADMIN_CONSTANTS,
        id,
        payload: data,
        updateType
    }
}

export function getAdminConstants(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().adminConstants, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'admin/constants',
                params
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getAdminConstant(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('adminConstant'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'admin/constants/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createAdminConstant(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'POST',
                url: fetchUrl + 'admin/constants',
                data
            }).then(res => {
                dispatch(reloadConstants())
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(update(res.data.data, false, 'create'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateAdminConstant(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('adminConstant'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'admin/constants/' + id,
                data
            }).then(res => {
                dispatch(reloadConstants())
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function deleteAdminConstant(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'DELETE',
                url: fetchUrl + 'admin/constants/' + id
            }).then(res => {
                dispatch(reloadConstants())
                dispatch(receiveItem({}, 'message.item_deleted'))
                dispatch(update(res.data.data, id, 'delete'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}
