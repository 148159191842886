import setAuthToken from "./setAuthToken"
import isEmpty from 'lodash/isEmpty'

export default (err, logout = false) => {
    if(logout){
        localStorage.removeItem(btoa(window.location.host + '_jwtToken'))
        setAuthToken(false)
    }
    let errors = {server: "message.unexpected_error"}
    if(err.response){
        if(!isEmpty(err.response.data.data)){
            errors = err.response.data.data
        }else if(err.response.data.message){
            errors = {server: err.response.data.message}
        }
    }
    return errors
}