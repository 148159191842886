import React from "react";

export default () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor"
              d="M96 96c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40zm128 0c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40zm128 0c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40zm48 160h-32V112h-32v144h-96V112h-32v144h-96V112H80v144H48c-26.5 0-48 21.5-48 48v208h448V304c0-26.5-21.5-48-48-48zm16 224H32v-72.043C48.222 398.478 55.928 384 74.75 384c27.951 0 31.253 32 74.75 32 42.843 0 47.217-32 74.5-32 28.148 0 31.201 32 74.75 32 43.357 0 46.767-32 74.75-32 18.488 0 26.245 14.475 42.5 23.955V480zm0-112.374C406.374 359.752 394.783 352 373.5 352c-43.43 0-46.825 32-74.75 32-27.695 0-31.454-32-74.75-32-42.842 0-47.218 32-74.5 32-28.148 0-31.202-32-74.75-32-21.463 0-33.101 7.774-42.75 15.658V304c0-8.822 7.178-16 16-16h352c8.822 0 16 7.178 16 16v63.626z"/>
    </svg>
  )
}
