import React from "react"
import {bindActionCreators} from "redux"
import {Link, NavLink} from "react-router-dom"
import {connect} from "react-redux"

import {
    clickOnMobileMenu,
    logoutUser,
} from "../../redux/actions"

import {MobileMenuIcon} from "../../components/svg"
import {routes} from "../../constants/constants"
import {Dropdown} from 'react-bootstrap'
import IntlMessages from "../../helpers/IntlMessages"
import Videomanual from "../../components/common/Videomanual"

const TopNav = (props) => {
    const mobileMenuButtonClick = (e,) => {
        e.preventDefault()
        props.actions.clickOnMobileMenu()
    }

    return (
        <nav className="navbar fixed-top">
            <div className="navbar-left d-flex align-items-center justify-content-start">
                <NavLink
                    to="#" location={{}}
                    className="menu-button-mobile d-xs-block d-sm-block d-md-none mr-3"
                    onClick={e => mobileMenuButtonClick(e)}
                >
                    <MobileMenuIcon/>
                </NavLink>
                <div className="navbar-logo-wrapper d-flex align-items-center">
                    <a className="navbar-logo" href={routes.calc_kalkulace}>
                        <span className="logo d-none d-xs-block"/>
                        <span className="logo-mobile d-block d-xs-none"/>
                    </a>
                </div>
                <Videomanual/>
            </div>
            <div className="navbar-right">
                <div className="user d-inline-block">
                    <Dropdown className="dropdown-menu-right">
                        <Dropdown.Toggle as="button" bsPrefix="p-0 btn btn-empty text-right">
                            <small><IntlMessages id="general.broker"/></small>
                            <p><span className="name">{props.auth.user.name}</span></p>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="mt-3">
                            <Dropdown.Item as="button">
                                <Link to={routes.userProfile}>
                                    {props.auth.user.name}
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item as="button" onClick={() => props.actions.logoutUser()}>
                                <IntlMessages id="general.logout"/>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </nav>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        info: state.info.data
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            clickOnMobileMenu,
            logoutUser,
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopNav)
