import {
    initialCalcFetchState,
    osoba,
    dite,
    hypoteky,
    pojisteni_majetku,
    pojisteni_osob,
    ostatni,
    pojisteni_vozidel,
    energie,
    sporeni,
    onemocneni,
    invalidita,
    dalsi_pripojisteni,
} from "../constants/defaultValues"
import _defaultsDeep from "lodash/defaultsDeep"
import _isObject from "lodash/isObject"
import _isArray from "lodash/isArray"

const currentData = initialCalcFetchState.data

const additionalInsurance_other_map = {
    invalidniPenze: 'invalidita',
    //zprosteniInvalidita: 'zprosteni_od_placeni',
    //umrtiKonstantneKlesajici: 'umrti_klesajici',
    //umrtiAnuitneKlesajici: 'umrti_anuitne_klesajici',
    //umrtiKonstantni: 'umrti_konstantni'
}

const outcomes_map = {
    pojisteniMajetku: 'pojisteni_majetku',
    pojisteniVozidel: 'pojisteni_vozidel',
    pojisteniOsob: 'pojisteni_osob',
    ostatni2: 'ostatni'
}

const outcomes_item_map = {
    datumOd: 'datum_od',
    datumDo: 'datum_do',
    rokRevize: 'rok_revize'
}

export default (oldData) => {
    if (oldData.version !== currentData.version) {
        const calc = {...currentData}
        let enums = {}
        let persons = []
        let children = []
        if (oldData.version < 2) {
            //ENUMS
            Object.keys(oldData.enums).map(key => {
                if (key == 'davky') {
                    enums.davky = oldData.enums[key]
                } else if (key == 'retirementAge') {
                    const dv = {}
                    Object.keys(oldData.enums[key]).map(year => {
                        dv[year] = {
                            muz: oldData.enums[key][year].male,
                            zena: oldData.enums[key][year].female,
                        }
                    })
                    enums.duchodovyVek = dv
                } else {
                    if (typeof oldData.enums[key] == "object") {
                        const o = {}
                        Object.keys(oldData.enums[key]).map(k => {
                            if (typeof oldData.enums[key][k] == "object") {
                                Object.keys(oldData.enums[key][k]).map(kk => {
                                    o[`${k}_${kk}`] = oldData.enums[key][k][kk]
                                })
                            } else {
                                o[k] = oldData.enums[key][k]
                            }
                        })
                        enums[key == 'settings' ? 'nastaveni' : key] = o
                    } else {
                        enums[key] = oldData.enums[key]
                    }
                }
            })
            const commonSettings = oldData.commonSettings
            //PERSONS
            const p = oldData.client.persons
            Object.keys(p).map(key => {
                const data = p[key]
                const vydaje = {bezne: {}}
                const oi = data.outcomes.input
                const ipi = data.incomesProtection.input
                const aiio = data.additionalInsurance.input.other.find(i => i.identifier === 'onemocneni') || {}
                const aii = data.additionalInsurance.input
                const pripojisteni_dalsi = {...dalsi_pripojisteni}
                Object.keys(oi).map(k => {
                    if (!_isObject(oi[k])) {
                        vydaje.bezne[k] = oi[k]
                    } else {
                        if (_isArray(oi[k])) {
                            const new_key = outcomes_map[k] || k

                            oi[k].map((item, kk) => {
                                if (!vydaje[new_key]) {
                                    vydaje[new_key] = []
                                }
                                const newVal = {}
                                Object.keys(oi[k][kk]).map(kkk => {
                                    if (kkk !== 'isDefault') {
                                        let newKey = outcomes_item_map[k] || k
                                        newVal[newKey] = oi[k][kk][kkk]
                                    }
                                })
                                let currentObj = {}
                                switch (new_key) {
                                    case 'hypoteky':
                                        currentObj = hypoteky
                                        break
                                    case 'energie':
                                        currentObj = energie
                                        break
                                    case 'pojisteni_vozidel':
                                        currentObj = pojisteni_vozidel
                                        break
                                    case 'pojisteni_majetku':
                                        currentObj = pojisteni_majetku
                                        break
                                    case 'pojisteni_osob':
                                        currentObj = pojisteni_osob
                                        break
                                    case 'ostatni2':
                                        currentObj = ostatni
                                        break
                                    case 'sporeni':
                                        currentObj = sporeni
                                        break
                                }
                                vydaje[new_key].push(_defaultsDeep({}, currentObj, newVal))
                            })
                        } else {
                            Object.keys(oi[k]).map(kk => {
                                if (!vydaje[k]) {
                                    vydaje[k] = {}
                                }
                                if (kk !== 'isDefault') {
                                    vydaje[k][kk] = oi[k][kk]
                                }
                            })
                        }
                    }
                })
                let pripojisteni_poznamka = ''
                aii.other.map(o => {
                    const newIdent = additionalInsurance_other_map[o.identifier]
                    if(newIdent){
                        pripojisteni_dalsi[newIdent] = {
                            zajisteno_pojistenim: o.zajistenoPojistenim,
                            realizace: o.realizace
                        }
                    }else if(o.zajistenoPojistenim > 0 || o.realizace > 0){
                        pripojisteni_poznamka += ` ${o.identifier} - zajistěno pojištěním: ${o.zajistenoPojistenim || 0}, realizace: ${o.realizace || 0}`
                    }
                })

                if(pripojisteni_poznamka){
                    pripojisteni_poznamka = aii.poznamka + 'další připojištění: ' + pripojisteni_poznamka
                }

                persons[key] = _defaultsDeep({}, {
                    jmeno: data.info.firstname,
                    prijmeni: data.info.lastname,
                    telefon: data.info.phone,
                    email: data.info.email,
                    typ: data.info.type,
                    vek: data.info.age,
                    datum_narozeni: data.info.birthDate,
                    rodne_cislo: data.info.birthNumber,
                    titul_za: data.info.degree_after,
                    titul_pred: data.info.degree_before,
                    id: data.info.id,
                    prijmy: {
                        typ: data.incomes.input.typ || 0,
                        hruby_mesicni_zamestnani: data.incomes.input.mesZamestnani,
                        rocni_podnikani: data.incomes.input.rocniPodnikani,
                        ostatni_podnikani: data.incomes.input.ostatniPodnikani,
                        duchod: data.incomes.input.duchod,
                        jine: data.incomes.input.jine,
                        uroky_hu: data.incomes.input.urokyHU,
                        dps: data.incomes.input.DPS,
                        zp: data.incomes.input.ZP,
                        ostatni: data.incomes.input.ostatni,
                        rezerva: data.incomes.input.rezerva,
                        poznamka: data.incomes.input.poznamka
                    },
                    vydaje: vydaje,
                    ochrana_prijmu: {
                        umrti: [{
                            zajisteno_pojistenim: ipi.umrti.zajistenoPojistenim,
                            volba: ipi.umrti.selection,
                            realizace: ipi.umrti.realizace,
                            nasobek: commonSettings.incomesProtection.input.umrti
                        }],
                        nasledky: [{
                            zajisteno_pojistenim: ipi.nasledky.zajistenoPojistenim,
                            volba: ipi.nasledky.selection,
                            realizace: ipi.nasledky.realizace,
                            nasobek: commonSettings.incomesProtection.input.nasledky
                        }],
                        invalidita: [{
                            zajisteno_pojistenim: ipi.invalidita.zajistenoPojistenim,
                            volba: ipi.invalidita.selection,
                            realizace: ipi.invalidita.realizace,
                            nasobek: commonSettings.incomesProtection.input.invalidita
                        }],
                        onemocneni: [{
                            zajisteno_pojistenim: aiio.zajistenoPojistenim,
                            realizace: aiio.realizace,
                        }],
                        ostatni: {
                            jina_pripojisteni: false,
                            poznamka: ipi.poznamka
                        }
                    },
                    pripojisteni: {
                        pracovni_neschopnost: [{
                            zajisteno_pojistenim: aii.neschopnost.zajistenoPojistenim,
                            volba: aii.neschopnost.selection,
                            realizace: aii.neschopnost.realizace,
                            nasobek: 1 - commonSettings.additionalInsurance.input.pripojisteni
                        }],
                        hospitalizace: [{
                            zajisteno_pojistenim: aii.hospitalizace.zajistenoPojistenim,
                            volba: aii.hospitalizace.selection,
                            realizace: aii.hospitalizace.realizace,
                            nasobek: 1 - commonSettings.additionalInsurance.input.pripojisteni
                        }],
                        uraz: [{
                            zajisteno_pojistenim: aii.uraz.zajistenoPojistenim,
                            volba: aii.uraz.selection,
                            realizace: aii.uraz.realizace,
                            nasobek: 1 - commonSettings.additionalInsurance.input.pripojisteni
                        }],
                        dalsi: pripojisteni_dalsi,
                        ostatni: {
                            poznamka: pripojisteni_poznamka
                        }
                    },
                    penze: {
                        inflace: commonSettings.retirement.input.inflace,
                        zhodnoceni: commonSettings.retirement.input.zhodnoceni,
                        dps_zhodnoceni: commonSettings.retirement.input.zhodnoceniDPS,
                        izp_zhodnoceni: commonSettings.retirement.input.zhodnoceniIZP,
                        investice_zhodnoceni: commonSettings.retirement.input.zhodnoceniInvestice,
                        pozadovana_mesicni_penze: data.retirement.input.prijmy.mesPenze,
                        doba_penze: data.retirement.input.prijmy.dobaPenze,
                        dps_nasporeno: data.retirement.input.duchod.DPSNasporeno,
                        izp_nasporeno: data.retirement.input.duchod.IZPNasporeno,
                        investice_nasporeno: data.retirement.input.duchod.investiceNasporeno,
                        dps_platba: data.retirement.input.duchod.DPSPlatba,
                        izp_platba: data.retirement.input.duchod.IZPPlatba,
                        investice_platba: data.retirement.input.duchod.investicePlatba,
                        volba: data.retirement.input.duchod.selection,
                        zahrnout_dps: data.retirement.input.duchod.zahrnoutDPS,
                        zahrnout_izp: data.retirement.input.duchod.zahrnoutIZP,
                        dps_zmena: data.retirement.input.zmeny.DPS,
                        izp_zmena: data.retirement.input.zmeny.IZP,
                        investice_zmena: data.retirement.input.zmeny.investice,
                        poznamka: data.retirement.input.poznamka
                    },
                    rezerva: {
                        nasobek: commonSettings.reserve.input.multiple,
                        volba: data.reserve.input.rezerva.selection,
                        ucet_nazev: data.reserve.input.dleVkladu.ucetNazev,
                        ucet_urok: data.reserve.input.dleVkladu.ucetUrok,
                        ucet_dan: data.reserve.input.dleVkladu.ucetDan,
                        ucet_poplatek: data.reserve.input.dleVkladu.ucetPoplatek,
                        navrh_urok: data.reserve.input.dleVkladu.navrhUrok,
                        navrh_dan: data.reserve.input.dleVkladu.navrhDan,
                        navrh_poplatek: data.reserve.input.dleVkladu.navrhPoplatek,
                        mesicni_vklad: data.reserve.input.dleVkladu.mesVklad,
                        doba_ulozeni: data.reserve.input.dleDoby.dobaUlozeni,
                        dohodnuto: data.reserve.input.dohodnuto,
                        poznamka: data.reserve.input.poznamka,
                    }
                }, osoba)
            })
            //CHILDREN
            const ch = oldData.client.children
            Object.keys(ch).map(key => {
                children[key] = _defaultsDeep({}, {
                    rodic: ch[key].info.parent,
                    jmeno: ch[key].info.name,
                    vek: ch[key].info.age,
                    datum_narozeni: ch[key].info.birthDate,
                    sporeni: {
                        typy: [{
                            vek_ukonceni: ch[key].savings.input.vekUkonceni,
                            pozadovano: ch[key].savings.input.pozadovano,
                            zajisteno_pojistenim: ch[key].savings.input.zajistenoPojistenim,
                            volba: ch[key].savings.input.selection,
                            realizace: ch[key].savings.input.realizace,
                            do_10: commonSettings.childrenSavings.input.do10,
                            do_19: commonSettings.childrenSavings.input.do19,
                            od_20: commonSettings.childrenSavings.input.od20
                        }]
                    },
                    pojisteni: {
                        nasledky: [{
                            zajisteno_pojistenim: ch[key].insurance.input.nasledky.zajistenoPojistenim,
                            volba: ch[key].insurance.input.nasledky.selection,
                            realizace: ch[key].insurance.input.nasledky.realizace,
                            nasobek: ch[key].insurance.input.nasledky.multiple,
                        }],
                        onemocneni: [{
                            zajisteno_pojistenim: ch[key].insurance.input.onemocneni.zajistenoPojistenim,
                            volba: ch[key].insurance.input.onemocneni.selection,
                            realizace: ch[key].insurance.input.onemocneni.realizace,
                            nasobek: ch[key].insurance.input.onemocneni.multiple,
                        }],
                        hospitalizace: [{
                            zajisteno_pojistenim: ch[key].insurance.input.hospitalizace.zajistenoPojistenim,
                            zprosteni_umrti:  ch[key].insurance.input.hospitalizace.umrtiPojistnika,
                            zprosteni_invalidita:  ch[key].insurance.input.hospitalizace.invaliditaPojistnika,
                            realizace:  ch[key].insurance.input.hospitalizace.realizace,
                            nasobek:  ch[key].insurance.input.hospitalizace.multiple
                        }],
                        uraz: [{
                            zajisteno_pojistenim: ch[key].insurance.input.uraz.zajistenoPojistenim,
                            zprosteni_umrti:  ch[key].insurance.input.uraz.umrtiPojistnika,
                            zprosteni_invalidita:  ch[key].insurance.input.uraz.invaliditaPojistnika,
                            realizace:  ch[key].insurance.input.uraz.realizace,
                            nasobek:  ch[key].insurance.input.uraz.multiple
                        }],
                    }
                }, dite)
            })
        }

        switch (oldData.version) {
            case 1:
            case 1.2:
                calc.enums = enums
                calc.osoby = persons
                calc.deti = children
                break
            default:
                break
        }
        return calc
    } else {
        return oldData
    }
}