import {
    REQUEST_ADMIN_STRINGS,
    RECEIVE_ADMIN_STRINGS,
    UPDATE_ADMIN_STRINGS,
    ERROR_ADMIN_STRINGS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem, receiveStrings} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import {updateStrings} from "../actions"
import axios from 'axios'

function request() {
    return {
        type: REQUEST_ADMIN_STRINGS
    };
}

export function receive(data) {
    return {
        type: RECEIVE_ADMIN_STRINGS,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_ADMIN_STRINGS,
        errors: data
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_ADMIN_STRINGS,
        id,
        payload: data,
        updateType
    }
}

export function getAdminStrings(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().adminStrings, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'admin/strings',
                params
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getString(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('adminString'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'admin/strings/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createString(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'POST',
                url: fetchUrl + 'admin/strings',
                data
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(update(res.data.data, false, 'create'))
                dispatch(updateStrings(res.data.data, 'create'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateString(data, id, locale) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('adminString'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'admin/strings/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                dispatch(updateStrings(res.data.data, 'update', locale))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function deleteString(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'DELETE',
                url: fetchUrl + 'admin/strings/' + id
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_deleted'))
                dispatch(update(res.data.data, id, 'delete'))
                dispatch(updateStrings(res.data.data, 'delete'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function importStrings() {
    let data = require('../../lang/locales/cs');

    return dispatch => {
        return axios({
            method: 'PATCH',
            url: fetchUrl + 'admin/strings',
            data: {
                data
            }
        }).then(res => {
            dispatch(receiveStrings(res.data.data))
            return true
        }).catch(err => {
            console.log(err)
            return false
        })
    }
}