import {
    REQUEST_VIDEOMANUALS,
    RECEIVE_VIDEOMANUALS,
    RELOAD_VIDEOMANUALS,
    ERROR_VIDEOMANUALS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'

function request() {
    return {
        type: REQUEST_VIDEOMANUALS
    };
}

export function receive(data) {
    return {
        type: RECEIVE_VIDEOMANUALS,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_VIDEOMANUALS,
        errors: data
    }
}

export function reloadVideomanuals() {
    return {
        type: RELOAD_VIDEOMANUALS,
    }
}

export function getVideomanuals(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().videomanuals, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'videomanuals',
                params
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}
