import React, {useEffect, useState} from "react"
import {connect} from "react-redux"
import {useLocation} from 'react-router-dom'
import getCurrentPathKey from "../../helpers/currentPathKey"
import {YoutubeIcon} from '../svg'
import isEmpty from 'lodash/isEmpty'
import IntlMessages from "../../helpers/IntlMessages"
import VideoModal from "../../containers/modals/VideoModal"

const Videomanual = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [visible, setVisible] = useState(false)
    const location = useLocation()
    const key = getCurrentPathKey(location.pathname.replace(/\/\d*-\d*$/, ''))

    useEffect(() => {
        setVisible(false)
        setTimeout(() => {
            setVisible(true)
        }, 500)
    }, [location.pathname])

    if(isEmpty(props.videomanuals) || !key || !key.includes('calc_')){
        return null
    }

    const tabName = key.replace('calc_', '')

    if(!props.videomanuals[tabName]){
        return null
    }

    return (
        <div className="ml-3 videomanual d-flex align-items-center">
            <i onClick={() => setModalOpen(props.videomanuals[tabName])}>
                <YoutubeIcon/>
            </i>
            <a onClick={() => setModalOpen(props.videomanuals[tabName])} className="d-none d-lg-flex align-items-center">
                <IntlMessages id="navigation.videomanual"/>
                <span className={visible ? "active" : ""}>
                    <span><IntlMessages id={`tab.${tabName}`}/></span>
                </span>
            </a>
            {
                modalOpen && <VideoModal modalOpen={modalOpen ? true : false} toggleModal={() => setModalOpen(false)} url={modalOpen} title={<IntlMessages id={`tab.${tabName}`}/>}/>
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        videomanuals: state.videomanuals.data
    }
}

export default connect(
    mapStateToProps,
    null
)(Videomanual)