import React from "react"
import IntlMessages from "../../helpers/IntlMessages"
import {FormattedDate, FormattedTime, FormattedNumber} from "react-intl"
import {NavLink} from "react-router-dom"
import PasswordField from "./PasswordField"
import {isArray, isEmpty} from 'lodash'
import {Badge} from "react-bootstrap"
import ItemEnums from "./ItemEnums"
import ItemPermissions from "./ItemPermissions"
import replaceSpaces from "../../helpers/replaceSpaces"
import replaceImglLink from "../../helpers/replaceImglLink"

function getItem(props) {
    const {type = 'string', value} = props
    switch (type) {
        case 'string':
            if(props.localizedPrefix){
                return <IntlMessages id={`${props.localizedPrefix}.${value}`}/>
            }else{
                return replaceSpaces(value)
            }
        case 'wysiwyg':
            return (
                <div>
                    {
                        isEmpty(value) ?  "--" : (
                            <div dangerouslySetInnerHTML={{__html: replaceImglLink(replaceSpaces(value))}} className="wysiwyg-preview"/>
                        )
                    }
                </div>
            )
        case 'boolean':
            return <IntlMessages id={value ? 'general.yes' : 'general.no'}/>
        case 'percentage':
            return <span>{value} %</span>
        case 'enums':
            if(props.enumType === 'permissions'){
                return <ItemPermissions field={{...props}} permissions={value || []}/>
            }else{
                return <ItemEnums field={{...props}} data={value || []}/>
            }
        case 'link':
            return <NavLink to={value}>{value}</NavLink>
        case 'outlink':
            return <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
        case 'password':
            return <PasswordField password={value} viewable={props.viewable}/>
        case 'date':
            return value ? <FormattedDate value={value}/> : '--'
        case 'time':
            return value ? <FormattedDate value={value}/> : '--'
        case 'price':
            const currency = props.currency || "CZK"
            const decimals = props.minimalDecimals ? (currency === 'CZK' ? 1 : 3) : false
            return <FormattedNumber value={value || 0}
                                    style="currency"
                                    minimumSignificantDigits={decimals === false ? undefined : decimals}
                                    currency={currency}/>
        case 'datetime':
            return value ? <><FormattedDate value={value}/>{" "}<FormattedTime value={value}/></> : '--'
        case 'badge':
            const badges = isArray(value) ? value : [value]
            return isEmpty(badges) ? <IntlMessages id="general.empty"/> : (
                badges.map((item, index) => {
                    return <Badge key={index} variant={item.type || 'primary'} className="mb-1 mr-1">{item.name}</Badge>
                })
            )
        case 'raw':
            const unit = props.unit ? ' '+props.unit : ''
            return value + unit
        default:
            return '--'
    }
}

export default (props) => {
    const Elm = props.as || 'p'
    return (
        <>
            {props.label && <p className="text-muted text-small mb-2"><IntlMessages id={props.label}/></p>}
            <Elm className={`mb-3 ${props.className}`}>{getItem(props)}</Elm>
        </>
    )
}