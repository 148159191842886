import React, {useEffect} from "react"

import TopNav from "../containers/navs/Topnav"
import Sidebar from "../containers/navs/Sidebar"
import Toaster from "../containers/Toaster"

import "react-perfect-scrollbar/dist/css/styles.css"
import "react-big-calendar/lib/css/react-big-calendar.css"
import 'react-image-lightbox/style.css'
import 'react-tagsinput/react-tagsinput.css'
import 'react-date-picker/dist/DatePicker.css'

import '../assets/css/sass/themes/app.default.scss'

const AppLayout = (props) => {
    useEffect(() => {
        document.body.id = "app"
    }, [])
    const {hideSidebar} = props

    return (
        <div id="app-container">
            <TopNav/>
            {
                !hideSidebar && <Sidebar/>
            }
            <main className={hideSidebar ? "sidebar-hidden" : ""}>
                <div className="container-fluid">
                    {props.children}
                </div>
            </main>
            <Toaster/>
        </div>
    )
}

export default AppLayout