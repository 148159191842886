import React from "react"
import UsersIcon from "../components/svg/menu/Users"
import KeyIcon from "../components/svg/menu/Key"
import ShieldIcon from "../components/svg/menu/Shield"
import StringIcon from "../components/svg/menu/Strings"
import ListIcon from "../components/svg/menu/List"
import QuestionIcon from "../components/svg/menu/Question"
import HexagoneIcon from "../components/svg/menu/Hexagone"
import ConstantIcon from "../components/svg/menu/Constant"
import AnniversaryIcon from "../components/svg/menu/Anniversary"
import {PdfIcon, YoutubeIcon} from "../components/svg"

export default [
    {
        id: "calc_kalkulace",
        icon: <ListIcon/>,
        label: "menu.calc-list",
    },{
        id: "anniversary_calculations",
        icon: <AnniversaryIcon/>,
        label: "menu.anniversary",
    },{
        id: "manual",
        icon: <QuestionIcon/>,
        label: "menu.manual",
        newWindow: true
    },{
        id: "bruno",
        icon: <HexagoneIcon/>,
        label: "menu.bruno",
        newWindow: true
    },
    {
        id: "admin",
        icon: <ShieldIcon/>,
        label: "menu.admin",
        subs: [
            {
                id: "admin_users",
                icon: <UsersIcon/>,
                label: "menu.users",
            }, {
                id: "admin_permissions",
                icon: <KeyIcon/>,
                label: "menu.permissions",
            }, {
                id: "admin_roles",
                icon: <ShieldIcon/>,
                label: "menu.roles",
            }, {
                id: "admin_strings",
                icon: <StringIcon/>,
                label: "menu.strings",
            }, {
                id: "admin_pdfs",
                icon: <PdfIcon/>,
                label: "menu.pdf-documents",
            }, {
                id: "admin_videomanuals",
                icon: <YoutubeIcon/>,
                label: "menu.videomanuals",
            }, {
                id: "admin_declarations",
                icon: <PdfIcon/>,
                label: "menu.declarations",
            }, {
                id: "admin_constants",
                icon: <ConstantIcon/>,
                label: "menu.constants",
            }
        ]
    },
]