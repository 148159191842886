import {
    REQUEST_CALCULATIONS,
    RECEIVE_CALCULATIONS,
    UPDATE_CALCULATIONS,
    ERROR_CALCULATIONS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'

function request() {
    return {
        type: REQUEST_CALCULATIONS
    };
}

export function receive(data) {
    return {
        type: RECEIVE_CALCULATIONS,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_CALCULATIONS,
        errors: data
    }
}

export function updateCalculationItem(data, id, updateType) {
    return {
        type: UPDATE_CALCULATIONS,
        id,
        payload: data,
        updateType
    }
}

export function getCalculations(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().calculations, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'calculations',
                params
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function lockCalculation(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('calculation'))
            return axios({
                method: 'PATCH',
                url: fetchUrl + 'calculations/lock/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(updateCalculationItem(res.data.data, id, 'update'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function unlockCalculation(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('calculation'))
            return axios({
                method: 'PATCH',
                url: fetchUrl + 'calculations/unlock/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(updateCalculationItem(res.data.data, id, 'update'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function copyCalculation(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('calculation'))
            return axios({
                method: 'POST',
                url: fetchUrl + 'calculations/copy/' + id
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(updateCalculationItem(res.data.data, false, 'create'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function deleteCalculation(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'DELETE',
                url: fetchUrl + 'calculations/' + id
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_deleted'))
                dispatch(updateCalculationItem(res.data.data, id, 'delete'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}
