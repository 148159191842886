import React, {useEffect} from 'react'
import {connect} from "react-redux"
import '../assets/css/sass/themes/app.default.scss'

const SinglePageLayout =  (props) => {
    useEffect(() => {
        document.body.id = "app"
    }, [])
    return(
        <main className="single-page">
            <img src={`/assets/img/logo/logo.png`} alt="logo"/>
            <div className="content-wrapper d-flex align-items-center justify-content-center justify-content-lg-end">
                <div className="depth-2">
                    {props.children}
                </div>
            </div>
        </main>
    )
}

function mapStateToProps(state) {
    return {
        info: state.info,
    }
}

export default connect(
    mapStateToProps,
    null
)(SinglePageLayout)