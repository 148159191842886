import {
    REQUEST_ITEM,
    RECEIVE_ITEM,
    UPDATE_ITEM,
    ERROR_ITEM
} from '../types'
import axios from "axios";
import {fetchUrl} from "../../constants/constants";

export function requestItem(key = '') {
    return {
        type: REQUEST_ITEM,
        payload: key
    };
}

export function receiveItem(data, message = '') {
    return {
        type: RECEIVE_ITEM,
        payload: data,
        message: message
    }
}

export function failedItem(data) {
    return {
        type: ERROR_ITEM,
        payload: data
    }
}

function update(data, updateType){
    return{
        type: UPDATE_ITEM,
        payload: data,
        updateType
    }
}

export function getLoginLog(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'users/login-log/' + (id || 0),
            params
        }).then(res => {
            dispatch(update(res.data.data, 'loginLog'))
            return true
        }).catch(err => {
            return false
        })
    )
}
