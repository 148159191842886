import React from "react"
import {Card, Modal} from "react-bootstrap"
import IframeYoutube from "../../components/common/IframeYoutube"

export default (props) => {
    return (
        <Modal
            show={props.modalOpen}
            onHide={props.toggleModal}
            backdrop="static"
            className="modal-video"
            size="xl"
        >
            <Modal.Header onHide={props.toggleModal} closeButton>
                <Modal.Title as="h5">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <IframeYoutube url={props.url}/>
            </Modal.Body>
        </Modal>
    )
}
