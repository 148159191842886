export const fetchUrl = process.env.NODE_ENV === 'production' ? '/api/' : 'http://saraonline.cz.local/api/'
export const mediaUrl = process.env.NODE_ENV === 'production' ? '/media/' : 'http://saraonline.cz.local/api/'
export const fileUrl = process.env.NODE_ENV === 'production' ? '/files/' : 'http://saraonline.cz.local/api/'

export const routes = {
    landing: '/prihlaseni',

    login: '/prihlaseni',
    changePassword: '/zmena-hesla',
    forgottenPassword: '/zapomenute-heslo',
    setPassword: '/nastaveni-hesla',
    userProfile: '/profil',
    error: '/chyba',

    manual: '#',
    bruno: '#',

    anniversary_calculations: '/vyrocni-kalkulace',

    calc_kalkulace: '/kalkulace',
    calc_klient: '/kalkulace/klient',
    calc_prijmy: '/kalkulace/prijmy',
    calc_vydaje: '/kalkulace/vydaje',
    calc_disponibilni_prostredky: '/kalkulace/disponibilni-prostredky',
    calc_ochrana_prijmu: '/kalkulace/ochrana-prijmu',
    calc_pripojisteni: '/kalkulace/pripojisteni',
    calc_deti_sporeni: '/kalkulace/deti-sporeni',
    calc_deti_pojisteni: '/kalkulace/deti-pojisteni',
    calc_penze: '/kalkulace/penze',
    calc_rezerva: '/kalkulace/rezerva',
    calc_dokonceni: '/kalkulace/dokonceni',

    admin: '/admin',
    admin_dashboard: '/admin/uzivatele',
    admin_users: '/admin/uzivatele',
    admin_roles: '/admin/role',
    admin_permissions: '/admin/opravneni',
    admin_strings: '/admin/texty',
    admin_pdfs: '/admin/pdf-dokumenty',
    admin_videomanuals: '/admin/videomanualy',
    admin_declarations: '/admin/pdf-prohlaseni',
    admin_constants: '/admin/konstanty',
}

export const routePermissions = {
    admin: {
        permissions: []
    },
    admin_users: {
        permissions: ['user.view']
    },
    admin_roles: {
        permissions: ['role.view']
    },
    admin_permissions: {
        permissions: ['permission.view']
    },
    admin_strings: {
        permissions: ['admin-string.view']
    },
    admin_videomanuals: {
        permissions: ['videomanual.view']
    },
    admin_pdfs: {
        permissions: ['pdf-document.view']
    },
    admin_declarations: {
        permissions: ['declaration.view']
    },
    admin_constants: {
        permissions: ['constant.view']
    },
    user: {
        permissions: []
    },
    userProfile: {
        permissions: []
    },
    manual: {
        permissions: []
    },
    bruno: {
        permissions: ['bruno.login']
    },
    anniversary_calculations: {
        permissions: ['calculation.view']
    },
    calc_kalkulace: {
        permissions: ['calculation.view']
    },
    calc_klient: {
        permissions: ['calculation.view']
    },
    calc_prijmy: {
        permissions: ['calculation.view']
    },
    calc_vydaje: {
        permissions: ['calculation.view']
    },
    calc_disponibilni_prostredky: {
        permissions: ['calculation.view']
    },
    calc_ochrana_prijmu: {
        permissions: ['calculation.view']
    },
    calc_pripojisteni: {
        permissions: ['calculation.view']
    },
    calc_deti_sporeni: {
        permissions: ['calculation.view']
    },
    calc_deti_pojisteni: {
        permissions: ['calculation.view']
    },
    calc_penze: {
        permissions: ['calculation.view']
    },
    calc_rezerva: {
        permissions: ['calculation.view']
    },
    calc_dokonceni: {
        permissions: ['calculation.view']
    },

}

export const enumsFetchRoutes = {
    stringCategories: '/string-categories',
    permissions: '/permissions',
    permActions: '/permission-actions',
    roles: '/roles',
    constantsGroups: '/constants-groups',
    academicDegreesBefore: '/academic-degrees-before',
    academicDegreesAfter: '/academic-degrees-after',
    users: '/users',
}

export const toasterDuration = 2500
