import {
    REQUEST_PERMISSIONS,
    RECEIVE_PERMISSIONS,
    UPDATE_PERMISSIONS,
    ERROR_PERMISSIONS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import {reloadEnums} from "../actions"
import axios from 'axios'

function request() {
    return {
        type: REQUEST_PERMISSIONS
    };
}

export function receive(data) {
    return {
        type: RECEIVE_PERMISSIONS,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_PERMISSIONS,
        errors: data
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_PERMISSIONS,
        id,
        payload: data,
        updateType
    }
}

export function getPermissions(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().permissions, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'admin/permissions',
                params
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getPermission(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('permission'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'admin/permissions/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createPermission(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'POST',
                url: fetchUrl + 'admin/permissions',
                data
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(update(res.data.data, false, 'create'))
                dispatch(reloadEnums('permissions'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updatePermission(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('permission'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'admin/permissions/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                dispatch(reloadEnums('permissions'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}