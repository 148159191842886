import {
    REQUEST_CALCULATIONS,
    RECEIVE_CALCULATIONS,
    UPDATE_CALCULATIONS,
    ERROR_CALCULATIONS
} from '../types'
import {initialFetchState} from '../../constants/defaultValues'

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_CALCULATIONS:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_CALCULATIONS:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                shouldReload: false,
                errors: {},
                message: '',
            }
        case UPDATE_CALCULATIONS:
            const data = state.data
            let index = null
            switch(action.updateType) {
                case 'create':
                    data.items.unshift(action.payload)
                    break
                case 'update':
                    if(data.items){
                        index = data.items.findIndex(item => item.id == action.id)
                        data.items[index] = action.payload
                    }
                    break
                case 'delete':
                    index = data.items.findIndex(item => item.id == action.id)
                    data.items.splice(index, 1)
                    break
                case 'main-note':
                    if(data.items){
                        index = data.items.findIndex(item => item.id == action.id)
                        if(index > -1){
                            data.items[index].main_note = action.payload
                        }
                    }
                    break
            }
            return {
                ...state,
                isFetching: false,
                data,
                errors: {}
            }
        case ERROR_CALCULATIONS:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}