import {
    REQUEST_CALCULATION,
    RECEIVE_CALCULATION,
    UPDATE_CALCULATION,
    ERROR_CALCULATION
} from '../types'
import {
    initialCalcFetchState,
    osoba,
    dite,
    hypoteky,
    pojisteni_vozidel,
    sporeni,
    pojisteni_osob,
    pojisteni_majetku,
    ostatni,
    energie,
    umrti,
    invalidita,
    nasledky,
    onemocneni,
    pracovni_neschopnost,
    hospitalizace,
    uraz,
    dite_sporeni,
    dite_hospitalizace,
    dite_nasledky,
    dite_onemocneni,
    dite_uraz,
    dite_invalidita,
    dite_osetrovani
} from '../../constants/defaultValues'
import transformCalcData from "../../helpers/transformCalcData"
import _set from 'lodash/set'
import _get from 'lodash/get'
import _defaultsDeep from 'lodash/defaultsDeep'
import _cloneDeep from 'lodash/cloneDeep'

const imports = {
    hypoteky,
    pojisteni_vozidel,
    sporeni,
    pojisteni_osob,
    pojisteni_majetku,
    ostatni,
    energie,
    umrti,
    invalidita,
    nasledky,
    onemocneni,
    pracovni_neschopnost,
    hospitalizace,
    uraz,
    dite_sporeni,
    dite_hospitalizace,
    dite_nasledky,
    dite_onemocneni,
    dite_uraz,
    dite_invalidita,
    dite_osetrovani
}

export default (state = initialCalcFetchState, action = {}) => {
    switch (action.type) {
        case REQUEST_CALCULATION:
            return {
                data: _cloneDeep(state.data),
                isFetching: true,
                isFetched: false,
                message: '',
                errors: {}
            }
        case RECEIVE_CALCULATION:
            const p = action.payload
            const d = transformCalcData(p.calc)
            if (p.calc.version < 2) {
                d.calc_date = p.date_created
            } else {
                d.calc_date = p.calc.calc_date
            }
            d.date_created = p.date_created
            d.date_finished = p.date_finished
            d.date_updated = p.date_updated
            d.is_example = p.is_example
            d.is_family = p.is_family
            d.locked = p.locked
            d.note = p.note || ''
            d.main_note = p.main_note || ''
            d.notif_date = p.notification_date || ''
            d.number = p.number
            d.broker = p.broker
            d.broker_email = p.broker_email
            d.broker_phone = p.broker_phone
            d.changed = p.calc.version < 2
            d.pdfhash = p.pdfhash
            return {
                ...state,
                isFetching: false,
                isFetched: true,
                data: d,
                shouldReload: false,
                errors: {},
                message: action.message || '',
            }
        case UPDATE_CALCULATION:
            let data = {...state.data}
            switch (action.updateType) {
                case 'reset':
                    data = _cloneDeep(initialCalcFetchState.data)
                    break
                case 'remove-partner':
                    data.osoby.pop()
                    data.deti.map((item, index) => {
                        data.deti[index].rodic = 0
                    })
                    break
                case 'add-partner':
                    data.osoby.push({...osoba})
                    break
                case 'remove-child':
                    data.deti.splice(action.payload, 1)
                    break
                case 'add-child':
                    data.deti.push({...dite})
                    break
                case 'add-outcome':
                    data.osoby[action.payload.osoba].vydaje[action.payload.type].push({...imports[action.payload.type]})
                    break
                case 'remove-outcome':
                    data.osoby[action.payload.osoba].vydaje[action.payload.type].splice(action.payload.item, 1)
                    break
                case 'add-income-protection':
                    data.osoby[action.payload.osoba].ochrana_prijmu[action.payload.type].push({...imports[action.payload.type]})
                    break
                case 'remove-income-protection':
                    data.osoby[action.payload.osoba].ochrana_prijmu[action.payload.type].splice(action.payload.item, 1)
                    break
                case 'add-additional-insurance':
                    data.osoby[action.payload.osoba].pripojisteni[action.payload.type].push({...imports[action.payload.type]})
                    break
                case 'remove-additional-insurance':
                    data.osoby[action.payload.osoba].pripojisteni[action.payload.type].splice(action.payload.item, 1)
                    break
                case 'add-children-saving':
                    data.deti[action.payload.osoba].sporeni.typy.push({...imports.dite_sporeni})
                    break
                case 'remove-children-saving':
                    data.deti[action.payload.osoba].sporeni.typy.splice(action.payload.item, 1)
                    break
                case 'add-children-insurance':
                    data.deti[action.payload.osoba].pojisteni[action.payload.type].push({...imports[action.payload.type]})
                    break
                case 'remove-children-insurance':
                    data.deti[action.payload.osoba].pojisteni[action.payload.type].splice(action.payload.item, 1)
                    break
                case 'ages':
                    Object.keys(action.payload.osoby).map(key => {
                        data.osoby[key].vek = action.payload.osoby[key]
                    })
                    Object.keys(action.payload.deti).map(key => {
                        data.deti[key].vek = action.payload.deti[key]
                    })
                    data.calc_date = new Date().toISOString()
                    break
                case 'main-note':
                    data.main_note = action.payload
                    break
                default:
                    _set(data, action.updateType, _defaultsDeep(action.payload, _get(data, action.updateType)))
                    break
            }
            if(action.updateType !== 'reset' && action.updateType !== 'enums' && action.updateType !== 'main-note'){
                data.changed = true
            }
            return {
                ...state,
                isFetching: false,
                isFetched: true,
                data,
                message: '',
                errors: {}
            }
        case ERROR_CALCULATION:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || 'message.unexpected_error',
            }
        default:
            return state
    }
}