import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {bindActionCreators} from "redux"
import ReactDOM from 'react-dom'
import { Nav, NavItem, Collapse } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { withRouter } from 'react-router-dom'
import {routePermissions, routes} from "../../constants/constants"
import IntlMessages from '../../helpers/IntlMessages'
import {checkRoutePermission} from '../../helpers/checkPermission'

import {
    setContainerClassnames,
    addContainerClassname,
    changeSelectedMenuHasSubItems
} from '../../redux/actions'

import menuItems from '../../constants/menu'

class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedParentMenu: '',
            viewingParentMenu: '',
            collapsedMenus: []
        }
    }

    handleWindowResize = event => {
        if (event && !event.isTrusted) {
            return
        }
        const { containerClassnames } = this.props
        let nextClasses = this.getMenuClassesForResize(containerClassnames)
        this.props.actions.setContainerClassnames(
            0,
            nextClasses.join(' '),
            this.props.selectedMenuHasSubItems
        )
    }

    handleDocumentClick = e => {
        const container = this.getContainer()
        let isMenuClick = false
        if (
            e.target &&
            e.target.classList &&
            (e.target.classList.contains('menu-button') ||
                e.target.classList.contains('menu-button-mobile'))
        ) {
            isMenuClick = true
        } else if (
            e.target.parentElement &&
            e.target.parentElement.classList &&
            (e.target.parentElement.classList.contains('menu-button') ||
                e.target.parentElement.classList.contains('menu-button-mobile'))
        ) {
            isMenuClick = true
        } else if (
            e.target.parentElement &&
            e.target.parentElement.parentElement &&
            e.target.parentElement.parentElement.classList &&
            (e.target.parentElement.parentElement.classList.contains('menu-button') ||
                e.target.parentElement.parentElement.classList.contains(
                    'menu-button-mobile'
                ))
        ) {
            isMenuClick = true
        }
        if (container.contains(e.target) || container === e.target || isMenuClick) {
            return
        }
        this.setState({
            viewingParentMenu: ''
        })
        this.toggle()
    }

    getMenuClassesForResize = classes => {
        const { menuHiddenBreakpoint, subHiddenBreakpoint } = this.props
        let nextClasses = classes.split(' ').filter(x => x !== '')
        const windowWidth = window.innerWidth

        if (windowWidth < menuHiddenBreakpoint) {
            nextClasses.push('menu-mobile')
        } else if (windowWidth < subHiddenBreakpoint) {
            nextClasses = nextClasses.filter(x => x !== 'menu-mobile')
            if (
                nextClasses.includes('menu-default') &&
                !nextClasses.includes('menu-sub-hidden')
            ) {
                nextClasses.push('menu-sub-hidden')
            }
        } else {
            nextClasses = nextClasses.filter(x => x !== 'menu-mobile')
            if (
                nextClasses.includes('menu-default') &&
                nextClasses.includes('menu-sub-hidden')
            ) {
                nextClasses = nextClasses.filter(x => x !== 'menu-sub-hidden')
            }
        }
        return nextClasses
    }

    getContainer = () => {
        return ReactDOM.findDOMNode(this)
    }

    toggle = () => {
        const hasSubItems = this.getIsHasSubItem()
        this.props.actions.changeSelectedMenuHasSubItems(hasSubItems)
        const { containerClassnames, menuClickCount } = this.props
        const currentClasses = containerClassnames
            ? containerClassnames.split(' ').filter(x => x !== '')
            : ''
        let clickIndex = -1
        if (!hasSubItems) {
            if (
                currentClasses.includes('menu-default') &&
                (menuClickCount % 4 === 0 || menuClickCount % 4 === 3)
            ) {
                clickIndex = 1
            } else if (
                currentClasses.includes('menu-sub-hidden') &&
                (menuClickCount === 2 || menuClickCount === 3)
            ) {
                clickIndex = 0
            } else if (
                currentClasses.includes('menu-hidden') ||
                currentClasses.includes('menu-mobile')
            ) {
                clickIndex = 0
            }
        } else {
            if (currentClasses.includes('menu-sub-hidden') && menuClickCount === 3) {
                clickIndex = 2
            } else if (
                currentClasses.includes('menu-hidden') ||
                currentClasses.includes('menu-mobile')
            ) {
                clickIndex = 0
            }
        }
        if (clickIndex >= 0) {
            this.props.actions.setContainerClassnames(
                clickIndex,
                containerClassnames,
                hasSubItems
            )
        }
    }

    handleProps = () => {
        this.addEvents()
    }

    addEvents = () => {
        ['click', 'touchstart', 'touchend'].forEach(event =>
            document.addEventListener(event, this.handleDocumentClick, true)
        )
    }

    removeEvents = () => {
        ['click', 'touchstart', 'touchend'].forEach(event =>
            document.removeEventListener(event, this.handleDocumentClick, true)
        )
    }

    setSelectedLiActive = callback => {
        const oldli = document.querySelector('.sub-menu  li.active')
        if (oldli != null) {
            oldli.classList.remove('active')
        }

        const oldliSub = document.querySelector('.third-level-menu  li.active')
        if (oldliSub != null) {
            oldliSub.classList.remove('active')
        }

        /* set selected parent menu */
        const selectedSublink = document.querySelector('.third-level-menu  a.active')
        if (selectedSublink != null) {
            selectedSublink.parentElement.classList.add('active')
        }

        const selectedlink = document.querySelector('.sub-menu  a.active')
        if (selectedlink != null) {
            selectedlink.parentElement.classList.add('active')
            this.setState(
                {
                    selectedParentMenu: selectedlink.parentElement.parentElement.getAttribute(
                        'data-parent'
                    )
                },
                callback
            )
        } else {
            var selectedParentNoSubItem = document.querySelector(
                '.main-menu  li a.active'
            )
            if (selectedParentNoSubItem != null) {
                this.setState(
                    {
                        selectedParentMenu: selectedParentNoSubItem.getAttribute(
                            'data-flag'
                        )
                    },
                    callback
                )
            } else if (this.state.selectedParentMenu === '') {
                this.setState(
                    {
                        selectedParentMenu: menuItems[0].id
                    },
                    callback
                )
            }
        }
    }

    setHasSubItemStatus = () => {
        const hasSubmenu = this.getIsHasSubItem()
        this.props.actions.changeSelectedMenuHasSubItems(hasSubmenu)
        this.toggle()
    }

    getIsHasSubItem = () => {
        const { selectedParentMenu } = this.state
        const menuItem = menuItems.find(x => x.id === selectedParentMenu)
        if (menuItem)
            return menuItem && menuItem.subs && menuItem.subs.length > 0
                ? true
                : false
        else return false
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setSelectedLiActive(this.setHasSubItemStatus)
            window.scrollTo(0, 0)
        }
        this.handleProps()
        if(this.props.containerClassnames !== prevProps.containerClassnames){
           this.setClassName()
        }
    }

    setClassName(){
        document.getElementById('app-container').className = this.props.containerClassnames
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize)
        this.handleWindowResize()
        this.handleProps()
        this.setSelectedLiActive(this.setHasSubItemStatus)
        this.setClassName()
    }

    componentWillUnmount() {
        this.removeEvents()
        window.removeEventListener('resize', this.handleWindowResize)
    }

    openSubMenu = (e, menuItem) => {
        const selectedParent = menuItem.id
        const hasSubMenu = menuItem.subs && menuItem.subs.length > 0
        this.props.actions.changeSelectedMenuHasSubItems(hasSubMenu)
        if (!hasSubMenu) {
            this.setState({
                viewingParentMenu: selectedParent,
                selectedParentMenu: selectedParent
            })
            this.toggle()
        } else {
            e.preventDefault()

            const { containerClassnames, menuClickCount } = this.props
            const currentClasses = containerClassnames
                ? containerClassnames.split(' ').filter(x => x !== '')
                : ''

            if (!currentClasses.includes('menu-mobile')) {
                if (
                    currentClasses.includes('menu-sub-hidden') &&
                    (menuClickCount === 2 || menuClickCount === 0)
                ) {
                    this.props.actions.setContainerClassnames(3, containerClassnames, hasSubMenu)
                } else if (
                    currentClasses.includes('menu-hidden') &&
                    (menuClickCount === 1 || menuClickCount === 3)
                ) {
                    this.props.actions.setContainerClassnames(2, containerClassnames, hasSubMenu)
                } else if (
                    currentClasses.includes('menu-default') &&
                    !currentClasses.includes('menu-sub-hidden') &&
                    (menuClickCount === 1 || menuClickCount === 3)
                ) {
                    this.props.actions.setContainerClassnames(0, containerClassnames, hasSubMenu)
                }
            } else {
                this.props.actions.addContainerClassname(
                    'sub-show-temporary',
                    containerClassnames
                )
            }
            this.setState({
                viewingParentMenu: selectedParent
            })
        }
    }

    toggleMenuCollapse = (e, menuKey) => {
        e.preventDefault()

        let collapsedMenus = this.state.collapsedMenus
        if (collapsedMenus.indexOf(menuKey) > -1) {
            this.setState({
                collapsedMenus: collapsedMenus.filter(x => x !== menuKey)
            })
        } else {
            collapsedMenus.push(menuKey)
            this.setState({
                collapsedMenus
            })
        }
        return false
    }

    render() {
        const {
            selectedParentMenu,
            viewingParentMenu,
            collapsedMenus
        } = this.state
        const {info, user} = this.props
        return (
            <div className="sidebar">
                <div className="main-menu">
                    <div className="scroll">
                        <PerfectScrollbar
                            options={{ suppressScrollX: true, wheelPropagation: false }}
                        >
                            <Nav className="list-unstyled flex-column" as="ul">
                                {menuItems &&
                                menuItems.map(item => {
                                    if(routePermissions[item.id] && routePermissions[item.id].masterOnly && !info.master){
                                        return null
                                    }
                                    if(!checkRoutePermission(item.id, user)){
                                        return null
                                    }
                                    return (
                                        <NavItem
                                            key={item.id}
                                            as="li"
                                            className={classnames({
                                                active:
                                                    (selectedParentMenu === item.id &&
                                                        viewingParentMenu === '') ||
                                                    viewingParentMenu === item.id
                                            })}
                                        >
                                            {item.newWindow ? (
                                                <a
                                                    href={item.id === 'manual' ? (info.documentUrlLocal + 'manual') : (item.id === 'bruno' ? user.bruno : routes[item.id])}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    {item.icon}
                                                    <IntlMessages id={item.label} />
                                                </a>
                                            ) : (
                                                <NavLink
                                                    to={routes[item.id]}
                                                    onClick={e => this.openSubMenu(e, item)}
                                                    data-flag={item.id}
                                                >
                                                    {item.icon}
                                                    <IntlMessages id={item.label} />
                                                </NavLink>
                                            )}
                                        </NavItem>
                                    )
                                })}
                            </Nav>
                        </PerfectScrollbar>
                    </div>
                </div>

                <div className="sub-menu">
                    <div className="scroll">
                        <PerfectScrollbar
                            options={{ suppressScrollX: true, wheelPropagation: false }}
                        >
                            {menuItems &&
                            menuItems.map(item => {
                                return (
                                    <Nav
                                        key={item.id}
                                        as="ul"
                                        className={classnames({
                                            'd-block':
                                                (this.state.selectedParentMenu === item.id &&
                                                    this.state.viewingParentMenu === '') ||
                                                this.state.viewingParentMenu === item.id
                                        })}
                                        data-parent={item.id}
                                    >
                                        {item.subs && item.subs.map((sub, index) => {
                                            if(routePermissions[sub.id] && routePermissions[sub.id].masterOnly && !info.master){
                                                return null
                                            }
                                            if(!checkRoutePermission(sub.id, user)){
                                                return null
                                            }
                                            return (
                                                <NavItem
                                                    key={`${item.id}_${index}`}
                                                    as="li"
                                                    className={`${
                                                        sub.subs && sub.subs.length > 0
                                                            ? 'has-sub-item'
                                                            : ''
                                                    }`}
                                                >
                                                    {sub.newWindow ? (
                                                        <a
                                                            href={routes[sub.id]}
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                        >
                                                            {sub.icon}
                                                            <IntlMessages id={sub.label} />
                                                        </a>
                                                    ) : sub.subs && sub.subs.length > 0 ? (
                                                        <Fragment>
                                                            <NavLink
                                                                className={`rotate-arrow-icon opacity-50 ${
                                                                    collapsedMenus.indexOf(
                                                                        `${item.id}_${index}`
                                                                    ) === -1
                                                                        ? ''
                                                                        : 'collapsed'
                                                                }`}
                                                                to={routes[sub.id]}
                                                                id={`${item.id}_${index}`}
                                                                onClick={e =>
                                                                    this.toggleMenuCollapse(
                                                                        e,
                                                                        `${item.id}_${index}`
                                                                    )
                                                                }
                                                            >
                                                                <i className="simple-icon-arrow-down" />{' '}
                                                                <IntlMessages id={sub.label} />
                                                            </NavLink>

                                                            <Collapse
                                                                in={
                                                                    collapsedMenus.indexOf(
                                                                        `${item.id}_${index}`
                                                                    ) === -1
                                                                }
                                                            >
                                                                <div>
                                                                    <Nav className="third-level-menu" as="ul">
                                                                        {sub.subs.map((thirdSub, thirdIndex) => {
                                                                            return (
                                                                                <NavItem
                                                                                    key={`${
                                                                                        item.id
                                                                                    }_${index}_${thirdIndex}`}
                                                                                    as="li"
                                                                                >
                                                                                    {thirdSub.newWindow ? (
                                                                                        <a
                                                                                            href={routes[thirdSub.id]}
                                                                                            rel="noopener noreferrer"
                                                                                            target="_blank"
                                                                                        >
                                                                                            {thirdSub.icon}
                                                                                            <IntlMessages
                                                                                                id={thirdSub.label}
                                                                                            />
                                                                                        </a>
                                                                                    ) : (
                                                                                        <NavLink to={routes[thirdSub.id]}>
                                                                                            {thirdSub.icon}
                                                                                            <IntlMessages
                                                                                                id={thirdSub.label}
                                                                                            />
                                                                                        </NavLink>
                                                                                    )}
                                                                                </NavItem>
                                                                            )
                                                                        })}
                                                                    </Nav>
                                                                </div>
                                                            </Collapse>
                                                        </Fragment>
                                                    ) : (
                                                        <NavLink to={routes[sub.id]}>
                                                            {sub.icon}
                                                            <IntlMessages id={sub.label} />
                                                        </NavLink>
                                                    )}
                                                </NavItem>
                                            )
                                        })}
                                    </Nav>
                                )
                            })}
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ menu, info, auth}) => {
    const {
        containerClassnames,
        subHiddenBreakpoint,
        menuHiddenBreakpoint,
        menuClickCount,
        selectedMenuHasSubItems
    } = menu
    return {
        containerClassnames,
        subHiddenBreakpoint,
        menuHiddenBreakpoint,
        menuClickCount,
        selectedMenuHasSubItems,
        info: info.data,
        user: auth.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            setContainerClassnames,
            addContainerClassname,
            changeSelectedMenuHasSubItems
        }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Sidebar))
