import React from "react"
import ReactPlayer from 'react-player'

export default (props) => {
    return (
        <div className="video-outer">
            <div className="video-inner">
                <ReactPlayer
                    url={props.url}
                    className='react-player'
                    width="100%"
                    height="100%"
                />
            </div>
        </div>
    )
}