/*
LocalStorage keys
*/
export const storage = {
    authentication: 'Authorization_' + btoa(window.location.host),
    sidebar: '__sidebar',
    cookie_settings: '__cs'
}

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden"

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

//cookie disclaimer
export const defaultCookieSettings = {
    is_default: true,
    cookies: {
        necessary: true,
        analytics: false
    }
}

// Toaster
export const defaultToaster = {
    toasterType: "OK",
    title: '',
    message: '',
    counter: 0
}

//fetch state
export const initialFetchState = {
    data: {},
    isFetching: false,
    shouldReload: false,
    errors: {},
    message: ''
}

//fetch sequence state
export const initialSequenceFetchState = {
    data: [],
    isFetching: false,
    errors: {},
    message: ''
}

const childrenAge = {}
for (let i = 1; i < 31; i++) {
    childrenAge[i] = {value: i, label: i}
}

export const vekDoziti = {
    muz: [75.23, 75.45, 75.47, 75.48, 75.49, 75.49, 75.50, 75.51, 75.52, 75.52, 75.53, 75.54, 75.55, 75.55, 75.56, 75.57, 75.58, 75.66, 75.71, 75.75, 75.79, 75.83, 75.87, 75.92, 75.96, 75.99, 76.03, 76.07, 76.11, 76.14, 76.18, 76.22, 76.27, 76.31, 76.36, 76.40, 76.45, 76.49, 76.55, 76.60, 76.66, 76.73, 76.81, 76.89, 76.98, 77.08, 77.19, 77.30, 77.42, 77.54, 77.68, 77.82, 77.97, 78.14, 78.33, 78.53, 78.74, 78.97, 79.21, 79.47, 79.74, 80.04, 80.35, 80.68, 81.02, 81.38, 81.76, 82.14, 82.54, 82.93, 83.35, 83.77, 84.20, 84.66, 85.13, 85.62, 86.13, 86.65, 87.19, 87.75, 88.33, 88.92, 89.54, 90.17, 90.82, 91.49, 92.17, 92.87, 93.59, 94.32, 95.07, 95.84, 96.62, 97.42, 98.23, 99.05, 99.88, 100.73, 101.59, 102.45, 103.30, 104.14, 104.92, 105.50],
    zena: [81.13, 81.30, 81.31, 81.33, 81.33, 81.34, 81.35, 81.35, 81.36, 81.36, 81.37, 81.37, 81.37, 81.37, 81.38, 81.38, 81.39, 81.43, 81.45, 81.46, 81.47, 81.49, 81.50, 81.52, 81.53, 81.55, 81.56, 81.58, 81.60, 81.61, 81.63, 81.65, 81.66, 81.68, 81.70, 81.73, 81.75, 81.78, 81.81, 81.84, 81.87, 81.91, 81.95, 81.99, 82.04, 82.09, 82.15, 82.21, 82.28, 82.36, 82.44, 82.52, 82.61, 82.71, 82.81, 82.91, 83.02, 83.14, 83.28, 83.42, 83.58, 83.74, 83.91, 84.09, 84.28, 84.48, 84.70, 84.92, 85.15, 85.38, 85.63, 85.90, 86.18, 86.47, 86.78, 87.10, 87.44, 87.81, 88.20, 88.61, 89.05, 89.52, 90.01, 90.54, 91.08, 91.65, 92.24, 92.86, 93.50, 94.17, 94.87, 95.59, 96.33, 97.09, 97.88, 98.68, 99.51, 100.35, 101.21, 102.09, 102.97, 103.87, 104.75, 105.50]
}

/*
 typ tabulky: [
 vek(0-35):{DPS%, IZP%, OFP%}
 vek(36-50):{DPS%, IZP%, OFP%}
 vek(51--):{DPS%, IZP%, OFP%}
 ]
 */
export const alokaceProstredku = {
    vse: [
        {
            dps: 0.2,
            izp: 0.3,
            investice: 0.5
        },
        {
            dps: 0.25,
            izp: 0.20,
            investice: 0.55
        },
        {
            dps: 0.5,
            izp: 0.1,
            investice: 0.4
        }
    ],
    bez_izp: [
        {
            dps: 0.2,
            izp: 0,
            investice: 0.8
        },
        {
            dps: 0.25,
            izp: 0,
            investice: 0.75
        },
        {
            dps: 0.5,
            izp: 0,
            investice: 0.5
        }
    ],
    bez_dps: [
        {
            dps: 0,
            izp: 0.2,
            investice: 0.8
        },
        {
            dps: 0,
            izp: 0.15,
            investice: 0.75
        },
        {
            dps: 0,
            izp: 0.1,
            investice: 0.8
        }
    ]
}

//enum fetch state
export const initialEnumFetchState = {
    'filter-boolean': {
        data: [
            {value: 1, label: 'yes'},
            {value: 0, label: 'no'},
            {value: null, label: 'any'},
        ],
        isFetching: false,
        shouldReload: false
    },
    'pdf-actions': {
        data: {
            download: {value: 'download', label: 'download'},
            show: {value: 'show', label: 'show'},
        },
        isFetching: false,
        shouldReload: false
    },
    'person-type-client': {
        data: {
            0: {value: 0, label: 'klient'},
            1: {value: 1, label: 'klientka'},
        },
        isFetching: false,
        shouldReload: false
    },
    'person-type-partner': {
        data: {
            0: {value: 0, label: 'manžel'},
            1: {value: 1, label: 'manželka'},
            2: {value: 2, label: 'partner'},
            3: {value: 3, label: 'partnerka'},
        },
        isFetching: false,
        shouldReload: false
    },
    'income-type': {
        data: {
            0: {value: 0, label: 'zaměstnání'},
            1: {value: 1, label: 'OSVČ'},
            2: {value: 2, label: 'ostatní'},
        },
        isFetching: false,
        shouldReload: false
    },
    'payment-frequency': {
        data: {
            1: {value: 1, label: 'měsíčně'},
            3: {value: 3, label: 'čtvrtletně'},
            6: {value: 6, label: 'pololetně'},
            12: {value: 12, label: 'ročně'},
        },
        isFetching: false,
        shouldReload: false
    },
    'client-selection': {
        data: {
            0: {value: 0, label: 'upravit'},
            1: {value: 1, label: 'nahradit'},
            2: {value: 2, label: 'ponechat'},
            3: {value: 3, label: 'zrušit', isDisabled: true},
        },
        isFetching: false,
        shouldReload: false
    },
    'client-selection-0': {
        data: {
            //0: {value: 0, label: 'upravit', isDisabled: true},
            //1: {value: 1, label: 'nahradit', isDisabled: true},
            0: {value: 0, label: 'upravit'},
            1: {value: 1, label: 'nahradit'},
            2: {value: 2, label: 'ponechat', isDisabled: true},
            3: {value: 3, label: 'zrušit', isDisabled: true},
        },
        isFetching: false,
        shouldReload: false
    },
    'client-selection-1-2': {
        data: {
            //0: {value: 0, label: 'upravit', isDisabled: true},
            //0: {value: 0, label: 'upravit'},
            1: {value: 1, label: 'nahradit'},
            2: {value: 2, label: 'ponechat'},
            3: {value: 3, label: 'zrušit', isDisabled: true},
        },
        isFetching: false,
        shouldReload: false
    },
    'energy-type': {
        data: {
            el: {value: 'el', label: 'elektřina'},
            pl: {value: 'pl', label: 'plyn'},
        },
        isFetching: false,
        shouldReload: false
    },
    'energy-years': {
        data: {
            '2018': {value: '2018', label: '2018'},
            '2019': {value: '2019', label: '2019'},
            '2020': {value: '2020', label: '2020'},
        },
        isFetching: false,
        shouldReload: false
    },
    'product-type': {
        data: {
            0: {value: 0, label: 'Konstantní'},
            1: {value: 1, label: 'Klesající - lineárně'},
            2: {value: 2, label: 'Klesající - anuitně'},
        },
        isFetching: false,
        shouldReload: false
    },
    'invalidity-degree': {
        data: {
            0: {value: 0, label: 'I.'},
            1: {value: 1, label: 'II.'},
            2: {value: 2, label: 'III.'},
        },
        isFetching: false,
        shouldReload: false
    },
    'children-age': {
        data: childrenAge,
        isFetching: false,
        shouldReload: false
    }
}

//enum fetch Item state
export const initialEnumItemFetchState = {
    data: [],
    isFetching: false,
    shouldReload: false
}

//user state
export const initialUserFetchState = {
    isAuthenticated: false,
    isFetching: false,
    user: {},
}

export const hypoteky = {
    nazev: '',
    instituce: '',
    datum_od: '',
    datum_do: '',
    platba: '',
    frekvence: 12,
    dluh: '',
    fixace: '',
    urok: '',
    rocni_urok_pro_odecty: ''
}

export const energie = {
    nazev: '',
    instituce: '',
    datum_od: '',
    datum_do: '',
    platba: '',
    frekvence: '',
    spotreba: '',
    rok_revize: ''
}

const majetek_ostatni = {
    nazev: '',
    instituce: '',
    datum_od: '',
    datum_do: '',
    platba: '',
    frekvence: '',
    poznamka: '',
    rok_revize: ''
}

export const pojisteni_majetku = {...majetek_ostatni}
export const ostatni = {...majetek_ostatni}

export const pojisteni_osob = {
    nazev: '',
    instituce: '',
    datum_od: '',
    datum_do: '',
    platba: '',
    frekvence: '',
    poznamka: '',
    rok_revize: '',
    danove_odecty: 0
}

export const pojisteni_vozidel = {
    nazev: '',
    instituce: '',
    datum_od: '',
    datum_do: '',
    platba: '',
    frekvence: '',
    spz: '',
    rok_revize: '',
}

export const sporeni = {
    nazev: '',
    instituce: '',
    datum_od: '',
    datum_do: '',
    platba: '',
    frekvence: '',
    urok: '',
    rok_revize: '',
    danove_odecty: 0
}

export const umrti = {
    typ_produktu: 0,
    zajisteno_pojistenim: '',
    volba: 0,
    realizace: '',
    nasobek: 2
}

export const nasledky = {
    typ_produktu: 0,
    zajisteno_pojistenim: '',
    volba: 0,
    realizace: '',
    nasobek: 10
}

export const invalidita = {
    typ_produktu: 0,
    zajisteno_pojistenim: '',
    volba: 0,
    realizace: '',
    nasobek: 3,
    zprosteni_od_placeni: false,
    stupen_invalidity: 0
}

export const onemocneni = {
    typ_produktu: 0,
    zajisteno_pojistenim: '',
    volba: 0,
    realizace: '',
    nasobek: 4,
}

export const pracovni_neschopnost = {
    zajisteno_pojistenim: '',
    volba: 0,
    realizace: '',
    nasobek: 0.2
}

export const hospitalizace = {
    zajisteno_pojistenim: '',
    volba: 0,
    realizace: '',
    nasobek: 0.2
}

export const uraz = {
    zajisteno_pojistenim: '',
    volba: 0,
    realizace: '',
    nasobek: 0.2
}

export const dalsi_pripojisteni = {
    invalidita: {
        zajisteno_pojistenim: '',
        realizace: ''
    },
    smrt_urazem: {
        zajisteno_pojistenim: '',
        realizace: ''
    },
    dalsi: {
        ano: 0
    }
}

export const osoba = {
    typ: 0,
    jmeno: '',
    prijmeni: '',
    telefon: '',
    email: '',
    vek: '',
    datum_narozeni: "",
    rodne_cislo: '',
    titul_za: [],
    titul_pred: [],
    prijmy: {
        typ: 0,
        hruby_mesicni_zamestnani: '',
        rocni_podnikani: '',
        ostatni_podnikani: '',
        duchod: '',
        jine: '',
        uroky_hu: '',
        dps: '',
        zp: '',
        ostatni: '',
        rezerva: '',
        kratkodoba_rezerva: '',
        castka_k_investovani: '',
        poznamka: ''
    },
    vydaje: {
        bezne: {
            najemne: '',
            udrzba: '',
            provoz: '',
            telefon: '',
            potraviny: '',
            odevy: '',
            obuv: '',
            auto: '',
            doprava: '',
            hobby: '',
            ostatni: '',
            poznamka: '',
        },
        hypoteky: [{...hypoteky}],
        energie: [{...energie}],
        energie_zjednoduseno: {
            typ_energie: 'el',
            rok: 2018,
            mesicni_cena_pl: '',
            konkurencni_cena_pl: '',
            spotreba_pl: '',
            mesicni_cena_el: '',
            konkurencni_cena_vt_el: '',
            spotreba_vt_el: '',
            konkurencni_cena_nt_el: '',
            spotreba_nt_el: ''
        },
        pojisteni_majetku: [{...pojisteni_majetku}],
        pojisteni_vozidel: [{...pojisteni_vozidel}],
        pojisteni_osob: [{...pojisteni_osob}],
        sporeni: [{...sporeni}],
        ostatni: [{...ostatni}],
    },
    ochrana_prijmu: {
        umrti: [{...umrti}],
        nasledky: [{...nasledky}],
        invalidita: [{...invalidita}],
        onemocneni: [{...onemocneni}],
        ostatni: {
            jina_pripojisteni: false,
            poznamka: ''
        }
    },
    pripojisteni: {
        pracovni_neschopnost: [{...pracovni_neschopnost}],
        hospitalizace: [{...hospitalizace}],
        uraz: [{...uraz}],
        dalsi: {...dalsi_pripojisteni},
        ostatni: {
            poznamka: ''
        },
    },
    penze: {
        pozadovana_mesicni_penze: '',
        doba_penze: '',
        dps_nasporeno: '',
        izp_nasporeno: '',
        investice_nasporeno: '',
        dps_platba: '',
        izp_platba: '',
        investice_platba: '',
        volba: 0,
        zahrnout_dps: 1,
        zahrnout_izp: 1,
        dps_zmena: '',
        izp_zmena: '',
        investice_zmena: '',
        inflace: 0.02,
        zhodnoceni: 0.02,
        dps_zhodnoceni: 0.03,
        izp_zhodnoceni: 0.03,
        investice_zhodnoceni: 0.04,
        poznamka: ''
    },
    rezerva: {
        nasobek: 3,
        volba: 0,
        mesicni_vklad: '',
        ucet_nazev: '',
        ucet_urok: '',
        ucet_dan: '',
        ucet_poplatek: '',
        navrh_urok: '',
        navrh_dan: '',
        navrh_poplatek: '',
        doba_ulozeni: '',
        dohodnuto: '',
        poznamka: ''
    }
}

export const dite_sporeni = {
    typ_produktu: '',
    vek_ukonceni: '',
    pozadovano: '',
    zajisteno_pojistenim: '',
    volba: 0,
    realizace: '',
    do_10: 0.02,
    do_19: 0.03,
    od_20: 0.04
}

const dite_nasledky_onemocneni_invalidita_osetrovani = {
    zajisteno_pojistenim: '',
    volba: 0,
    realizace: '',
    nasobek: 1
}

export const dite_nasledky = {...dite_nasledky_onemocneni_invalidita_osetrovani}

export const dite_onemocneni = {...dite_nasledky_onemocneni_invalidita_osetrovani}

export const dite_invalidita = {...dite_nasledky_onemocneni_invalidita_osetrovani}

export const dite_osetrovani = {...dite_nasledky_onemocneni_invalidita_osetrovani}

const dite_hospitalizace_uraz = {
    zajisteno_pojistenim: '',
    zprosteni_umrti: 0,
    zprosteni_invalidita: 0,
    realizace: '',
    nasobek: 1
}

export const dite_hospitalizace = {...dite_hospitalizace_uraz}

export const dite_uraz = {...dite_hospitalizace_uraz}

export const dite = {
    jmeno: '',
    datum_narozeni: '',
    rodic: 0,
    vek: '',
    sporeni: {
        typy: [{...dite_sporeni}],
        ostatni: {
            poznamka: ''
        }
    },
    pojisteni: {
        nasledky: [{...dite_nasledky}],
        onemocneni: [{...dite_onemocneni}],
        invalidita: [{...dite_invalidita}],
        osetrovani: [{...dite_osetrovani}],
        hospitalizace: [{...dite_hospitalizace}],
        uraz: [{...dite_uraz}],
        ostatni: {
            jina_pripojisteni: 0,
            poznamka: ''
        }
    }
}

//calc state
export const initialCalcFetchState = {
    isFetching: false,
    data: {
        osoby: [
            {...osoba}
        ],
        deti: [],
        enums: [],
        calc_date: new Date().toISOString(), //used as year for client and children ages
        note: '',
        main_note: '',
        notif_date: '',
        changed: false,
        version: 2.0
    },
    errors: {},
    message: ''
}

