import { combineReducers } from 'redux'
import toaster from './toaster/reducer'
import auth from './auth/reducer'
import info from './info/reducer'
import strings from './strings/reducer'
import item from './item/reducer'
import sequence from './sequence/reducer'
import users from './users/reducer'
import roles from './roles/reducer'
import permissions from './permissions/reducer'
import adminStrings from './adminStrings/reducer'
import filters from './filters/reducer'
import {locale, cookieSettings} from './settings/reducer'
import pdfDocuments from './pdfDocuments/reducer'
import adminVideomanuals from './adminVideomanuals/reducer'
import videomanuals from './videomanuals/reducer'
import declarations from './declarations/reducer'
import adminConstants from './adminConstants/reducer'
import calcNotifications from './calcNotifications/reducer'
import calculations from './calculations/reducer'
import calculation from './calculation/reducer'
import menu from './menu/reducer'
import constants from './constants/reducer'

import enums from './enums/reducer'

export default combineReducers({
  toaster,
  auth,
  info,
  strings,
  item,
  sequence,
  users,
  roles,
  permissions,
  enums,
  adminStrings,
  filters,
  locale,
  cookieSettings,
  pdfDocuments,
  adminVideomanuals,
  videomanuals,
  declarations,
  adminConstants,
  calcNotifications,
  calculations,
  calculation,
  menu,
  constants
})