/*TOASTER*/
export const SET_TOASTER = "SET_TOASTER"

/*COOKIE*/
export const SET_COOKIE_SETTINGS = "SET_COOKIE_SETTINGS"

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES"
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME"
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU"
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS"

/* FILTERS */
export const SET_FILTER_DATA = "SET_FILTER_DATA"
export const TOGGLE_FILTER = "TOGGLE_FILTER"

/* AUTH */
export const REQUEST_CURRENT_USER = "REQUEST_CURRENT_USER"
export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER"
export const ERROR_CURRENT_USER = "ERROR_CURRENT_USER"

/* INFO */
export const REQUEST_INFO = "REQUEST_INFO"
export const RECEIVE_INFO = "RECEIVE_INFO"
export const ERROR_INFO = "ERROR_INFO"


/* STRINGS */
export const REQUEST_STRINGS = "REQUEST_STRINGS"
export const RECEIVE_STRINGS = "RECEIVE_STRINGS"
export const UPDATE_STRINGS = "UPDATE_STRINGS"
export const ERROR_STRINGS = "ERROR_STRINGS"
export const TOGGLE_DEV_STRINGS = "TOGGLE_DEV_STRINGS"

/* ADMIN STRINGS */
export const REQUEST_ADMIN_STRINGS = "REQUEST_ADMIN_STRINGS"
export const RECEIVE_ADMIN_STRINGS = "RECEIVE_ADMIN_STRINGS"
export const UPDATE_ADMIN_STRINGS = "UPDATE_ADMIN_STRINGS"
export const ERROR_ADMIN_STRINGS = "ERROR_ADMIN_ROLES"

/* ADMINS */
export const REQUEST_USERS = "REQUEST_USERS"
export const RECEIVE_USERS = "RECEIVE_USERS"
export const UPDATE_USERS = "UPDATE_USERS"
export const ERROR_USERS = "ERROR_USERS"

/* ROLES */
export const REQUEST_ROLES = "REQUEST_ROLES"
export const RECEIVE_ROLES = "RECEIVE_ROLES"
export const UPDATE_ROLES = "UPDATE_ROLES"
export const ERROR_ROLES = "ERROR_ROLES"

/* PERMISSIONS */
export const REQUEST_PERMISSIONS = "REQUEST_PERMISSIONS"
export const RECEIVE_PERMISSIONS = "RECEIVE_PERMISSIONS"
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS"
export const ERROR_PERMISSIONS = "ERROR_PERMISSIONS"

/* PDF-DOCUMENTS */
export const REQUEST_PDF_DOCUMENTS = "REQUEST_PDF_DOCUMENTS"
export const RECEIVE_PDF_DOCUMENTS = "RECEIVE_PDF_DOCUMENTS"
export const UPDATE_PDF_DOCUMENTS = "UPDATE_PDF_DOCUMENTS"
export const ERROR_PDF_DOCUMENTS = "ERROR_PDF_DOCUMENTS"

/* ADMIN-VIDEOMANUALS */
export const REQUEST_ADMIN_VIDEOMANUALS = "REQUEST_ADMIN_VIDEOMANUALS"
export const RECEIVE_ADMIN_VIDEOMANUALS = "RECEIVE_ADMIN_VIDEOMANUALS"
export const UPDATE_ADMIN_VIDEOMANUALS = "UPDATE_ADMIN_VIDEOMANUALS"
export const ERROR_ADMIN_VIDEOMANUALS = "ERROR_ADMIN_VIDEOMANUALS"

/* VIDEOMANUALS */
export const REQUEST_VIDEOMANUALS = "REQUEST_VIDEOMANUALS"
export const RECEIVE_VIDEOMANUALS = "RECEIVE_VIDEOMANUALS"
export const RELOAD_VIDEOMANUALS = "RELOAD_VIDEOMANUALS"
export const ERROR_VIDEOMANUALS = "ERROR_VIDEOMANUALS"

/* CALC-NOTIFICATIONS */
export const REQUEST_CALC_NOTIFICATIONS = "REQUEST_CALC_NOTIFICATIONS"
export const RECEIVE_CALC_NOTIFICATIONS = "RECEIVE_CALC_NOTIFICATIONS"
export const UPDATE_CALC_NOTIFICATIONS = "UPDATE_CALC_NOTIFICATIONS"
export const ERROR_CALC_NOTIFICATIONS = "ERROR_CALC_NOTIFICATIONS"

/* DECLARATIONS */
export const REQUEST_DECLARATIONS = "REQUEST_DECLARATIONS"
export const RECEIVE_DECLARATIONS = "RECEIVE_DECLARATIONS"
export const UPDATE_DECLARATIONS = "UPDATE_DECLARATIONS"
export const ERROR_DECLARATIONS = "ERROR_DECLARATIONS"

/* ADMIN-CONSTANTS */
export const REQUEST_ADMIN_CONSTANTS = "REQUEST_ADMIN_CONSTANTS"
export const RECEIVE_ADMIN_CONSTANTS = "RECEIVE_ADMIN_CONSTANTS"
export const UPDATE_ADMIN_CONSTANTS = "UPDATE_ADMIN_CONSTANTS"
export const ERROR_ADMIN_CONSTANTS = "ERROR_ADMIN_CONSTANTS"

/* CONSTANTS */
export const REQUEST_CONSTANTS = "REQUEST_CONSTANTS"
export const RECEIVE_CONSTANTS = "RECEIVE_CONSTANTS"
export const RELOAD_CONSTANTS = "RELOAD_CONSTANTS"
export const ERROR_CONSTANTS = "ERROR_CONSTANTS"

/* ENUMS */
export const REQUEST_ENUMS = "REQUEST_ENUMS"
export const RECEIVE_ENUMS = "RECEIVE_ENUMS"
export const RELOAD_ENUMS = "RELOAD_ENUMS"
export const ERROR_ENUMS = "ERROR_ENUMS"

/* ITEM */
export const REQUEST_ITEM = "REQUEST_ITEM"
export const RECEIVE_ITEM = "RECEIVE_ITEM"
export const UPDATE_ITEM = "UPDATE_ITEM"
export const ERROR_ITEM = "ERROR_ITEM"

/* SEQUENCE */
export const REQUEST_SEQUENCE = "REQUEST_SEQUENCE"
export const RECEIVE_SEQUENCE = "RECEIVE_SEQUENCE"
export const ERROR_SEQUENCE = "ERROR_SEQUENCE"

/* CALCULATIONS */
export const REQUEST_CALCULATIONS = "REQUEST_CALCULATIONS"
export const RECEIVE_CALCULATIONS = "RECEIVE_CALCULATIONS"
export const UPDATE_CALCULATIONS = "UPDATE_CALCULATIONS"
export const ERROR_CALCULATIONS = "ERROR_CALCULATIONS"

/* CALCULATION */
export const REQUEST_CALCULATION = "REQUEST_CALCULATION"
export const RECEIVE_CALCULATION = "RECEIVE_CALCULATION"
export const UPDATE_CALCULATION = "UPDATE_CALCULATION"
export const ERROR_CALCULATION = "ERROR_CALCULATION"
