import React, {Suspense, useEffect} from 'react'
import {bindActionCreators} from 'redux'
import isEmpty from 'lodash/isEmpty'
import {connect} from 'react-redux'
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom'
import {IntlProvider} from 'react-intl'
import {routes} from "./constants/constants"
import SuspenseFallback from "./components/SuspenseFallback"
import {getInfo, getStrings, importStrings, toggleDevStrings, getCurrentUser} from './redux/actions'
import ErrorPage from './views/error'
import axios from "axios"
import {detect} from 'detect-browser'
import classnames from 'classnames'
import jwtDecode from "jwt-decode"
import setAuthToken from "./helpers/setAuthToken"
import Dev from "./components/common/Dev"
import {storage} from "./constants/defaultValues"
import AuthRoute from "./AuthRoute"
import NotauthRoute from "./NotauthRoute"

const browser = detect()

const LoginPage = React.lazy(() =>
    import( './views/login')
)
const ChangePasswordPage = React.lazy(() =>
    import( './views/change-password')
)
const ForgottenPasswordPage = React.lazy(() =>
    import( './views/forgotten-password')
)
const ResetPasswordPage = React.lazy(() =>
    import( './views/reset-password')
)
const ViewApp = React.lazy(() =>
    import('./views')
)

axios.defaults.headers.common['Optional'] = "DfWvpqvd9RbLFku75VL9JZpJKvmMJ84rdB5AssAmQPauB42c"
axios.defaults.headers.common['Browser'] = btoa(JSON.stringify(browser))
axios.defaults.headers.post['Content-Type'] = 'application/json'

const isIE = browser.name === 'ie'

const App = (props) => {
    const {auth, info, strings, locale} = props

    useEffect(() => {
        if (isEmpty(info.data)) {
            props.actions.getInfo()
        }
        if (isEmpty(auth.user)) {
            const jwt = localStorage.getItem(storage.authentication)
            if (jwt) {
                const decoded = jwtDecode(jwt)
                if (decoded && decoded.expiration > (Date.now() / 1000)) {
                    setAuthToken(jwt)
                    props.actions.getCurrentUser()
                } else {
                    setAuthToken()
                }
            }
        }
        props.actions.getStrings()
    }, [])

    useEffect(() => {
        if (auth.isAuthenticated) {
            document.addEventListener('keydown', (e) => processStrings(e))
            return document.removeEventListener('keydown', (e) => processStrings(e))
        }
    }, [auth.isAuthenticated])

    function processStrings(e) {
        if (!e.repeat) {
            if (e.ctrlKey && e.key === 'i') {
                if (auth.user.master) {
                    props.actions.importStrings()
                }
            } else if (e.altKey && e.key === 's') {
                props.actions.toggleDevStrings()
            }
        }
    }

    return (
        <div className={classnames("h-100", isIE && "is-ie")}>
            <IntlProvider
                locale={locale === 'cz' ? 'cs' : 'sk'}
                messages={strings.data}
            >
                {
                    info.isFetching || strings.isFetching || auth.isFetching ?
                        <SuspenseFallback/>
                        :
                        (
                            isEmpty(info.errors) ?
                                <Suspense fallback={<SuspenseFallback/>}>
                                    <Router>
                                        <Switch>
                                            <AuthRoute
                                                path={routes.changePassword}
                                                exact
                                                auth={auth}
                                                component={ChangePasswordPage}
                                            />
                                            <NotauthRoute
                                                path={routes.login}
                                                exact
                                                auth={auth}
                                                component={LoginPage}
                                            />
                                            <NotauthRoute
                                                path={routes.forgottenPassword}
                                                exact
                                                auth={auth}
                                                component={ForgottenPasswordPage}
                                            />
                                            <NotauthRoute
                                                path={routes.setPassword + '/:hash'}
                                                exact
                                                auth={auth}
                                                component={ResetPasswordPage}
                                            />
                                            <Route
                                                path='/'
                                                render={() => <ViewApp auth={auth}/>}
                                            />
                                        </Switch>
                                        <Dev/>
                                    </Router>
                                </Suspense>
                                :
                                <ErrorPage/>
                        )
                }
            </IntlProvider>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        info: state.info,
        strings: state.strings,
        locale: state.locale
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getInfo,
            getStrings,
            getCurrentUser,
            importStrings,
            toggleDevStrings
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)
