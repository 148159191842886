module.exports = {
    'general.i-agree-with': 'Souhlasím s ',
    'general.protection-of-personal-data': 'Prohlášením o ochraně osobních údajů',
    'order.store': 'Obchod',
    'order.store-email': 'obchod@cgm.com',
    'order.store-phone': '+420 607 889 887',
    'order.support': 'Podpora',
    'order.support-email': 'podpora@cgm.com',
    'order.support-phone': '+420 607 889 887',
    'general.dev-mail-info': 'V testovacím prostředí nebyl email reálně odeslán. Náhled zobrazíte ',
    'general.here': 'zde',
    'input.sk-SK': 'Slovensky',
    'general.recipients': 'Přijemce emailu',
    'message.order-error-message': 'Omlouváme se, ale během odesílání emailu došlo k nějaké chybě. Zkuste akci opakovat za chvíli nebo aktualizujte stránku.',
    'order.expertise-help': 'Omlouváme se, ale během odesílání emailu došlo k nějaké chybě. Zkuste akci opakovat za chvíli nebo aktualizujte stránku.',
    'demo.phone-verification-sms': "Ověřovací kód pro MEDISTAR DEMO je {{code}}.",
    'demo.verification-code-invalid': "Ověřovací kód není platný",
    'demo.phone-verification-enter-info': "Zadejte kód z SMS",
    'demo.phone-verification-heading': "Ověření tel. čísla",
    'demo.phone-verification-check-info': "Zkontrolujte tel číslo...",
    'general.verify': "Ověřit",
    'message.phone-verification-error-message': "Došlo k chybě, zkuste to prosím za chvíli."
}
