import React from "react"
import {
    Route,
    Redirect
} from 'react-router-dom'
import {routes} from "./constants/constants"
import {checkRoutePermission} from "./helpers/checkPermission"
import NoPermissionInfo from "./components/common/NoPermissionInfo"

import getCurrentPathKey from "./helpers/currentPathKey"

export default ({component: Component, auth, redirectTo, ...rest}) => {
    let key = getCurrentPathKey(rest.location.pathname.replace(/\/\d*$/, ''))
    return (
        <Route
            {...rest}
            render={props => {
                return !redirectTo && auth.isAuthenticated ? (
                    auth.isFetching ? null : (
                        checkRoutePermission(key, auth.user) ?
                            <Component {...props} auth={auth} />
                            :
                            <NoPermissionInfo/>
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: redirectTo || routes.login,
                            state: {from: props.location}
                        }}
                    />
                )
            }
            }
        />
    )
}