import {
    REQUEST_CALC_NOTIFICATIONS,
    RECEIVE_CALC_NOTIFICATIONS,
    UPDATE_CALC_NOTIFICATIONS,
    ERROR_CALC_NOTIFICATIONS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'

function request() {
    return {
        type: REQUEST_CALC_NOTIFICATIONS
    };
}

export function receive(data) {
    return {
        type: RECEIVE_CALC_NOTIFICATIONS,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_CALC_NOTIFICATIONS,
        errors: data
    }
}

function update(id) {
    return {
        type: UPDATE_CALC_NOTIFICATIONS,
        id
    }
}

export function getCalcNotification(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().calcNotifications, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'calculations/notifications',
                params
            }).then(res => {
                dispatch(receive(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
                return false
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function dismissCalcNotification(id) {
    return (dispatch) => {
        return axios({
            method: 'PUT',
            url: fetchUrl + 'calculations/notifications/' + id
        }).then(res => {
            dispatch(update(id))
            return true
        }).catch(err => {
            const errors = fetchErrorCtrl(err)
            return errors
        })
    }
}