import {
    REQUEST_CONSTANTS,
    RECEIVE_CONSTANTS,
    RELOAD_CONSTANTS,
    ERROR_CONSTANTS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'

function request() {
    return {
        type: REQUEST_CONSTANTS
    };
}

export function receive(data) {
    return {
        type: RECEIVE_CONSTANTS,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_CONSTANTS,
        errors: data
    }
}

export function reloadConstants() {
    return {
        type: RELOAD_CONSTANTS,
    }
}

export function getConstants(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().constants, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'constants',
                params
            }).then(res => {
                dispatch(receive(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
                return false
            })
        } else {
            return Promise.resolve()
        }
    }
}
