import {
    REQUEST_CALCULATION,
    RECEIVE_CALCULATION,
    UPDATE_CALCULATION,
    ERROR_CALCULATION
} from '../types'
import {fetchUrl} from '../../constants/constants'
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'

function request() {
    return {
        type: REQUEST_CALCULATION
    };
}

function receive(data, message = '') {
    return {
        type: RECEIVE_CALCULATION,
        payload: data,
        message
    }
}

export function modifyCalculationData(data, type) {
    return {
        type: UPDATE_CALCULATION,
        payload: data,
        updateType: type
    }
}

function failed(data) {
    return {
        type: ERROR_CALCULATION,
        errors: data
    }
}

export function getCalculation(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().calculation)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'calculations/' + id
            }).then(res => {
                dispatch(receive(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createCalculation(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().calculation)) {
            dispatch(request())
            return axios({
                method: 'POST',
                url: fetchUrl + 'calculations',
                data
            }).then(res => {
                dispatch(receive(res.data.data, 'message.calculation_saved'))
                return res.data.data.number
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateCalculation(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().calculation)) {
            dispatch(request())
            return axios({
                method: 'PUT',
                url: fetchUrl + 'calculations/' + id,
                data
            }).then(res => {
                dispatch(receive(res.data.data, 'message.calculation_saved'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function finishCalculation(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().calculation)) {
            dispatch(request())
            return axios({
                method: 'PATCH',
                url: fetchUrl + 'calculations/finish/' + id,
                data
            }).then(res => {
                dispatch(receive(res.data.data, 'message.calculation_finished'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}