import React from "react"
import {Badge} from "react-bootstrap"
import IntlMessages from "../../helpers/IntlMessages"

export default (props) => {
    const {field, data} = props
    let itm = null
    let variant = 'primary'

    if(typeof field.ifCond !== "undefined"){
        if(field.ifCond == data){
            itm = <Badge pill variant={field.variant || variant}>{field.ifVal}</Badge>
        }
    }else{
        let s = null
        if(field.localizedPrefix){
            s = <IntlMessages id={`${field.localizedPrefix}.${data}`}/>
            if(field.variants){
                variant = field.variants[data]
            }
        }else{
            s = data
        }
        if(field.variant){ // force override variant
            variant = field.variant
        }
        itm = <Badge pill variant={variant} className={props.className}>{s}</Badge>
    }

    return itm
}