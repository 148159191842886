import {
    REQUEST_CALC_NOTIFICATIONS,
    RECEIVE_CALC_NOTIFICATIONS,
    UPDATE_CALC_NOTIFICATIONS,
    ERROR_CALC_NOTIFICATIONS
} from '../types'
import {initialFetchState} from '../../constants/defaultValues'

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_CALC_NOTIFICATIONS:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_CALC_NOTIFICATIONS:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: '',
            }
        case UPDATE_CALC_NOTIFICATIONS:
            const data = state.data
            let index = data.items.findIndex(item => item.id == action.id)
            data.items.splice(index, 1)
            return {
                ...state,
                isFetching: false,
                data,
                errors: {}
            }
        case ERROR_CALC_NOTIFICATIONS:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}