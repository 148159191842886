import {
    REQUEST_ENUMS,
    RECEIVE_ENUMS,
    RELOAD_ENUMS,
    ERROR_ENUMS
} from '../types'
import {fetchUrl, enumsFetchRoutes} from '../../constants/constants'
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import axios from 'axios'
import isEmpty from "lodash/isEmpty"

function request(enumType) {
    return {
        type: REQUEST_ENUMS,
        enumType
    };
}

export function receive(data, enumType) {
    return {
        type: RECEIVE_ENUMS,
        payload: data,
        enumType
    }
}

function failed(data) {
    return {
        type: ERROR_ENUMS,
        errors: data
    }
}

export function reloadEnums(enumType) {
    return {
        type: RELOAD_ENUMS,
        enumType,
    }
}

function shouldFetch(fetch, enumType, force) {
    if (!fetch) {
        return true
    }else{
        const f = fetch[enumType]
        if (!f){
            return true
        }else if(f.isFetching) {
            return false
        } else if (force || isEmpty(f.data) || f.shouldReload){
            return true
        } else {
            return false
        }
    }
}

export function getEnums(enumType, params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().enums, enumType, force)) {
            dispatch(request(enumType))
            let type = isEmpty(params) ? enumType : enumType.split('.')[0]
            let par = isEmpty(params) ? params : {[params.param]: enumType.replace(type + '.', '')}
            return axios({
                method: 'GET',
                url: fetchUrl + 'enums' + enumsFetchRoutes[type],
                params: par
            }).then(res => {
                dispatch(receive(res.data.data, enumType))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors, enumType))
            })
        } else {
            return Promise.resolve()
        }
    }
}