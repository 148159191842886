import {
    REQUEST_STRINGS,
    RECEIVE_STRINGS,
    UPDATE_STRINGS,
    ERROR_STRINGS,
    TOGGLE_DEV_STRINGS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import axios from 'axios'

function request() {
    return {
        type: REQUEST_STRINGS
    };
}

export function receiveStrings(data, message = '') {
    return {
        type: RECEIVE_STRINGS,
        payload: data,
        message: message
    }
}

function failed(data) {
    return {
        type: ERROR_STRINGS,
        errors: data
    }
}

export function updateStrings(data, updateType) {
    return {
        type: UPDATE_STRINGS,
        payload: data,
        updateType
    }
}

export function getStrings() {
    return dispatch => {
        dispatch(request())
        return axios({
            method: 'GET',
            url: fetchUrl + 'strings'
        }).then(res => {
            dispatch(receiveStrings(res.data.data))
        }).catch(err => {
            const errors = fetchErrorCtrl(err)
            dispatch(failed(errors))
        })
    }
}

export function toggleDevStrings() {
    return {
        type: TOGGLE_DEV_STRINGS
    }
}