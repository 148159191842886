import {
    REQUEST_DECLARATIONS,
    RECEIVE_DECLARATIONS,
    UPDATE_DECLARATIONS,
    ERROR_DECLARATIONS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'

function request() {
    return {
        type: REQUEST_DECLARATIONS
    };
}

export function receive(data) {
    return {
        type: RECEIVE_DECLARATIONS,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_DECLARATIONS,
        errors: data
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_DECLARATIONS,
        id,
        payload: data,
        updateType
    }
}

export function getDeclarations(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().declarations, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'admin/declarations',
                params
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getDeclaration(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('declaration'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'admin/declarations/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createDeclaration(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'POST',
                url: fetchUrl + 'admin/declarations',
                data
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(update(res.data.data, false, 'create'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateDeclaration(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('declaration'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'admin/declarations/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function deleteDeclaration(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'DELETE',
                url: fetchUrl + 'admin/declarations/' + id
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_deleted'))
                dispatch(update(res.data.data, id, 'delete'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}
